.search-container {
  margin-bottom: 20px;
  display: flex;
  flex-wrap: wrap;
  /* flex-direction: row; */
  justify-content: space-between;
  

}

input[type='text'],[type = "number"],select {
  padding: 8px;
  margin: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
}

button {
  padding: 8px 16px;
  border-radius: 4px;
  background-color: #4caf50;
  color: #fff;
  border: none;
  cursor: pointer;
  margin-left: 10px;
}

table {
  width: 98%;
  border-collapse: collapse;
  margin: 10px;
  /* display: flex;
  flex-direction: column; */
  /* flex-wrap: wrap; */
}

th,
td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: left;
}

th {
  background-color: #f2f2f2;
}

.pagination {
  margin: 15px 15px 0 0;
}

.pagination > button {
  background-color: #ddd;
  color: #333;
  border: none;
  padding: 4px 8px;
  cursor: pointer;
  margin-right: 4px;
}

.pagination button.active {
  background-color: #4caf50;
  color: #fff;
}
.loader-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  /* background: rgba(0, 0, 0, 0.834); */
  z-index: 1;
}

.spinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #447cdc transparent #3d5af1 transparent;
  border-radius: 50%;
  animation: spin-anim 1.2s linear infinite;
}

@keyframes spin-anim {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}